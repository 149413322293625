<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.email-verify.request.title') }}
			</h1>
			<span class="d-inline-block mb-2">{{ $vuetify.lang.t('$vuetify.email-verify.request.subtitle', {email: $store.state.auth.identifier}) }}</span>
			<v-chip
				class="mb-0"
				outlined
				link
				@click="$emit('progress', true);$router.push({ name: 'signin-identifier', query: { continue: query.continue } })"
			>
				<v-avatar left>
					<v-icon color="secondary">
						mdi-account-circle
					</v-icon>
				</v-avatar>
				{{ $store.state.auth.identifier }}
				<v-avatar right>
					<v-icon color="secondary">
						mdi-chevron-down
					</v-icon>
				</v-avatar>
			</v-chip>
		</div>

		<div class="d-flex justify-space-between mt-10">
			<v-btn
			  class="text-none ml-auto"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.email-verify.request.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'email-verify-request',
	props: {
		token: { type: String, default: '' },
		query: { type: Object, default() { return {} } }
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.email-verify.request.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	mounted() {
		this.$emit('progress', false);
	},
	methods: {
		next() {
			this.$emit('progress', true);
			this.$store.dispatch('alert/hide');

			this.$store.dispatch("auth/email-verify-request", { 
				token: this.token
			}).then(response => {
				this.$router.push({ name: 'email-verify-success', query: { continue: this.query.continue }, params: { success: true } });
			}).catch(error => {
				this.$emit('progress', false);
		    	this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.error.server')});
				if (error.response) {
		            if ([401, 404].includes(error.response.status)) {
						this.error.passconf = null;
	            		this.$router.push({ name: 'rejected-token', query: { continue: this.query.continue } });
	            	}
		        }

				this.$store.dispatch('alert/show');
			});
		}
	}
}
</script>